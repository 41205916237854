<div class="settings-container text-center">
 <app-logo></app-logo>
  <div class="settings text-left">
    <h1>{{'settings-page.settings' | translate | titlecase}}</h1>
    <h3>{{'settings-page.language' | translate | translate}}</h3>
    <div class="select-language">
      <select class="form-select" [ngModel]="currentLanguage" (ngModelChange)="onSelectChange($event)">
        <option *ngFor="let lang of langCodes"
                [selected]="lang === currentLanguage"
                [ngValue]="lang"
        >
          {{getLangName(lang).name | titlecase}}
        </option>
      </select>
      <div class="change">{{'settings-page.change' | translate | uppercase}}</div>
    </div>
    <!-- <h3>{{'settings-page.view-mode' | translate | translate}}</h3>
    <div class="select-view-mode">
     <select class="form-select" [ngModel]="viewMode" (ngModelChange)="onViewModeChange($event)">
      <option *ngFor="let mode of viewModes"
                [selected]="mode === viewMode"
                [ngValue]="mode"
        >
          {{mode | titlecase}}
        </option>
     </select>
     <div class="change">{{'settings-page.change' | translate | uppercase}}</div>
      
    </div> -->
  </div>
  <div class="policies">
    <div>
      <a (click)="openLink(privacyURL)">Privacy Policy</a>
    </div>
    <div>
      <a (click)="openLink(tosURL)">Terms of Service</a>
    </div>
  </div>
  <div class="version text-center" *ngIf="appVersion && appBuildCode">
    V.{{appVersion}}
    <br/>
    {{'settings-page.code' | translate | uppercase}} {{appBuildCode}}
    <!-- {{'settings-page.code' | translate | uppercase}} 2.1.2 -->
  </div>
  
</div>