import { Component, ElementRef, Inject, OnDestroy, OnInit, NgZone } from '@angular/core';
import { TourModel } from './guru-api/models/tour.model';
import { GuruApiToursService } from './guru-api/guru-api-tours.service';
import { GuruApiAppService } from './guru-api/guru-api-app.service';
import { AppDetailsModel } from './guru-api/models/app-details.model';
import { GuruApiAboutService } from './guru-api/guru-api-about.service';
import { Observable, Subject, Subscriber, Subscription } from 'rxjs';
import { AppAboutModel } from './guru-api/models/app-about.model';
import { MoreMenuStateService } from './services/more-menu-state.service';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { AppConfigService } from './services/app-config/app-config.service';
import { GuruApiIteminfoService } from './guru-api/guru-api-iteminfo.service';
import { TourItemModel } from './guru-api/models/tour-item.model';
import { DisplaySettingsService } from './services/display-settings/display-settings.service';
import { environment } from '../environments/environment';
import { WINDOW } from './window';
import { LoadingService } from './services/loading/loading.service';
import { map, takeUntil } from 'rxjs/operators';
import { GuruFirebaseService } from './services/guru-firebase/guru-firebase.service';
import { GuruApiCategoryService } from './guru-api/guru-api-category.service';
import { GuruApiEventService } from './guru-api/guru-api-events.service';
import { Router, NavigationEnd } from '@angular/router';
import { Network } from '@capacitor/network';
import { SplashScreen } from '@capacitor/splash-screen';
import Firebase from '../app/plugins/firebase';
import { Preferences } from '@capacitor/preferences';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { StatusBar, Style } from '@capacitor/status-bar';
import { AlertController } from '@ionic/angular';
import { Browser } from '@capacitor/browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'web-app';
  unsubscribe$: Subject<void> = new Subject<void>();
  activePageURL = 'home'
  // Test code showing how to load data from endpoints
  //
  constructor(private guruApiToursService: GuruApiToursService,
    private guruApiAppService: GuruApiAppService,
    private guruApiAboutService: GuruApiAboutService,
    private guruApiIteminfoService: GuruApiIteminfoService,
    private moreMenuStateService: MoreMenuStateService,
    private translate: TranslateService,
    private cookieService: CookieService,
    private appConfigService: AppConfigService,
    private displaySettingsService: DisplaySettingsService,
    private elementRef: ElementRef,
    private loadingService: LoadingService,
    private guruFirebaseService: GuruFirebaseService,
    private guruApiCategoryService: GuruApiCategoryService,
    private guruApiEventService: GuruApiEventService,
    @Inject(WINDOW) private window: Window,
    private router: Router,
    private zone: NgZone,
    private alertController: AlertController
  ) {
    this.loadDevicePlatform();
    this.initializeApp();
    console.log("device loaded")
    if (this.isWeb()) {
      if (cookieService.get('language')) {
        translate.setDefaultLang(cookieService.get('language'));
        appConfigService.language = cookieService.get('language')
      } else if (appConfigService.language) {
        translate.setDefaultLang(appConfigService.language);
      } else {
        translate.setDefaultLang('en');
      }
    } else {
      Preferences.get({ key: "language" }).then((value) => {
        console.log("language val: ", value.value)
        if (value.value) {
          translate.setDefaultLang(value.value)
          appConfigService.language = value.value
          // cookieService.set('language', value.value)
        } else {
          translate.setDefaultLang("en")
          // appConfigService.language = "en"
          // cookieService.set('language', "en")
        }
      })
    }


    // App domain is hardcoded in env. Remove for prod build.
    if (window.location.hostname.substr(0, 3) === 'www') {
      environment.apiDomainName = window.location.hostname.substr(4);
    } else {
      environment.apiDomainName = window.location.hostname;
    }

    if (window.location.hostname === 'localhost' || window.location.hostname == "192.168.0.123") {
      environment.apiDomainName = 'sch.guruwebapp.com';
    }
    environment.apiDomainName = 'sch.guruwebapp.com';
    this.loadDetails();
 
    
  }

  initializeApp() {
    StatusBar.setStyle({ style: Style.Dark })
    StatusBar.setBackgroundColor({
      color: "#000000"
    })

    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        // Example url: https://beerswift.app/tabs/tab2
        // slug = /tabs/tab2
        const slug = event.url.split(".info").pop();
        if (slug) {
          this.router.navigateByUrl(slug);
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    });
    // StatusBar.hide()
      // Preferences.get({
      //   key: "view-mode"
      // }).then((value) => {
      //   console.log("value: ", value)
      //   // if (value.value) {
      //   //   StatusBar.setStyle({ style: value.value as Style });
      //   // } else {
          // StatusBar.setStyle({ style: Style.Default})
      //   // }
      // })
      
    
  }

  ngOnInit(): void {

    document.addEventListener('gesturestart', (event) => { event.preventDefault(); }, false);
    Network.addListener('networkStatusChange', status => {
      if (!status.connected) {
        this.loadingService.noConnection();
      }
    });
    Network.getStatus().then((status) => {
      if (!status.connected) {
        this.loadingService.noConnection();
      }
    })
    this.router.events.subscribe((event) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      Firebase.logPageView({
        pageName: event.url,
      })
      console.log("url", event.url)
      this.activePageURL = event.url.substring(1).split('/')[0]
      // window.scrollTo(0, 0)
    });
    this.displaySettingsService.getConfig().subscribe(config => {
      if (config.primaryColor) {
        this.elementRef.nativeElement.style.setProperty('--primary-color', config.primaryColor);
      }
      if (config.secondaryColor) {
        this.elementRef.nativeElement.style.setProperty('--secondary-color', config.secondaryColor);
      }
    });

    this.appConfigService.didLangChange().subscribe(state => {
      console.log(state);
      this.loadDetails();
    });

    this.loadingService.appState.subscribe(state => {
      if (state && +environment.appId) {
        this.loadData();
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getState(): boolean {
    return this.moreMenuStateService.getState();
  }

  loadItemInfo(): void {
    const traceItemsInfoLoad = this.guruFirebaseService.performance.trace('Items Info Initial Load');
    traceItemsInfoLoad.start();
    this.guruApiIteminfoService.loadItemsInfo()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          this.guruApiIteminfoService.setItemsInfo(data.objects);
          traceItemsInfoLoad.stop();
        },
        error => {
          if (error.error.detail === 'Invalid page.') {
          }
          traceItemsInfoLoad.stop();
        });
  }

  loadTours(): void {
    const traceToursLoad = this.guruFirebaseService.performance.trace('Tours Initial Load');

    traceToursLoad.start();
    this.guruApiToursService.loadTours()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          this.guruApiToursService.setTours(data.objects)
          console.log('Tours loaded!');
          traceToursLoad.stop();
        },
        error => {
          if (error.error.detail === 'Invalid page.') {
          }
          console.log('error', error);
          traceToursLoad.stop();
        });
  }
  loadEvents(): void {
    this.guruApiEventService.loadEvents()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        this.guruApiEventService.setEvents(data.objects)
        console.log('Events loaded!');
      }, error => {
        if (error.error.detail === 'Invalid page.') {
        }
        console.log('error', error);
      })
    // .pipe(maop)
  }
  loadDetails(): void {
    // const traceDetailsLoad = this.guruFirebaseService.performance.trace('App Details Initial Load');
    this.loadingService.appState.next(false);

    // traceDetailsLoad.start();
    this.guruApiAppService.loadAppDetails()
      .pipe(takeUntil(this.unsubscribe$))
      .pipe(map(result => {
        if (result.objects[0].web_app_item_search_fields) {
          const data = result;
          data.objects[0].web_app_item_search_fields = data.objects[0].web_app_item_search_fields.toString()
            .trim()
            .split(',');
          return data.objects[0];
        }
        return result.objects[0];
      }))
      .subscribe(
        data => {
          const appDetails = data;
          this.versionCheck(appDetails)
          this.guruApiAppService.setAppDetails(appDetails);
          if (appDetails) {
            this.displaySettingsService.setConfig({
              primaryColor: appDetails.web_app_primary_color,
              museumLogo: appDetails.web_app_logo_image.original,
              splashLogo: appDetails.web_app_splashscreen_image.original
            });

            if (appDetails.web_app_firebase_config) {
              const firebaseConfig = JSON.parse(appDetails.web_app_firebase_config.replace(/'/g, '"'));
              this.guruFirebaseService.initializeFirebase(firebaseConfig);
            }
            console.log(appDetails)

            environment.appId = appDetails.id;
            console.log(environment.appId)

            
          } else {
            throw new Error('Failed to initialize App');
          }
          this.loadingService.appState.next(true);
          this.guruApiAppService.loadAppFullDetails()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(data => {
              this.guruApiAppService.setAppDetails(data)
            })
          // traceDetailsLoad.stop();
        }, error => {
          if (error.error.detail === 'Invalid page.') {
          }
          console.log('error', error);
          // traceDetailsLoad.stop();
        }
      );
  }

  loadAbout(): void {
    const traceAboutLoad = this.guruFirebaseService.performance.trace('About Details Initial Load');

    traceAboutLoad.start();
    this.guruApiAboutService.loadAppAbout()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          this.guruApiAboutService.setAppAbout(data);
          console.log('About info loaded!');
          traceAboutLoad.stop();
        }, error => {
          if (error.error.detail === 'Invalid page.') {
          }
          console.log('error', error);
          traceAboutLoad.stop();
        }
      );
  }

  loadCategory(): void {
    const traceCategoriesLoad = this.guruFirebaseService.performance.trace('Categories Initial Load');

    traceCategoriesLoad.start();
    this.guruApiCategoryService.loadCategories()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        this.guruApiCategoryService.setCategories(data.objects);
        console.log('Categories loaded!');
        traceCategoriesLoad.stop();
      }, error => {
        console.log('error', error);
        traceCategoriesLoad.stop();
      });
  }
  loadDevicePlatform(): void {
    this.guruApiAppService.getDeviceInfo()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        this.guruApiAppService.setDevicePlatform(data.platform);
      }, error => {
        console.log('error', error)
      })
  }

  loadData(): void {
    this.loadAbout();
    this.loadItemInfo();
    this.loadTours();
    this.loadCategory();
    this.loadEvents();

  }
  isWeb(): boolean {
    return this.guruApiAppService.deviceInfo == "web"
  }
  onActivate(e, outlet) {
    console.log(outlet.scrollTop)
    outlet.scrollTop = 0;
  }
  hasInternet(): boolean {
    return !this.loadingService.noInternet
  }
  versionCheck(appDetails: AppDetailsModel) {
    App.getInfo().then(async (val) => {
      // console.log(val)
      let messageText = ''
      let storeUrl = '';
      let showAlert = false
      switch (this.guruApiAppService.deviceInfo) {
        case 'android': {
          if (appDetails.android_version > val.version) {
            messageText = 'To continue using this app, update to the latest version from the Play Store.'
            storeUrl = appDetails.google_play_store_url
            showAlert = true;
          }
          break
        }
        case 'ios': {
          if (appDetails.ios_version > val.version) {
            messageText = 'To continue using this app, update to the latest version from the App Store.'
            storeUrl = appDetails.apple_store_url
            showAlert = true;
          }
        }
        break
      }
      if (showAlert) {
        const alert = await this.alertController.create({
          header: 'Your app is out of date.',
          // subHeader: 'New Version Available',
          message: messageText,
          backdropDismiss: false,
          buttons: [
            {
              text: 'Update',
              // role: 'confirm',
              handler: () => {
                // console.log('clicked get')  
                if (storeUrl) {
                  Browser.open({
                    url: storeUrl
                  })
                }
                return false
              }
            }
          ]
        })
        alert.present();
      }

    })
  }
}
