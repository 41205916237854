<div class="container-fluid">
    <h1>Hours</h1>
    <div class="today" *ngIf="today">
        <p>Today</p>
        <span *ngIf="!today.closed">
            {{today.timeOpen}} - {{today.timeClose}}
        </span>
        <span *ngIf="today.closed">
            CLOSED
        </span>
    </div>
    <div class="week" *ngIf="formattedHours">
        <div *ngFor="let day of formattedHours" class="weekday" [ngClass]="{'specialHours': day.specialHours}">
            <span>
                {{day.dayName}}
                <span style="display: block;">{{day.prettyDate}}</span>
            </span>
            <span *ngIf="!day.closed">
                {{day.timeOpen}} - {{day.timeClose}}
            </span>
            <span *ngIf="day.closed">
                CLOSED
            </span>
        </div>
    </div>
</div>