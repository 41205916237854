import { Component, OnInit } from '@angular/core';
import { GuruApiAppService } from '../guru-api/guru-api-app.service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoadingService } from '../services/loading/loading.service';
import { OpeningHours } from '../guru-api/models/app-details.model';
import * as moment from 'moment';

interface HourDisplay {
  dayName: string
  timeOpen: string
  timeClose: string,
  fullDate: string,
  closed: boolean
  prettyDate: string
  specialHours: boolean
}
@Component({
  selector: 'app-hours-page',
  templateUrl: './hours-page.component.html',
  styleUrls: ['./hours-page.component.scss']
})
export class HoursPageComponent implements OnInit {
  unsubscribe$: Subject<void> = new Subject<void>();
  hours: OpeningHours = null;
  today: HourDisplay = null;
  formattedHours: HourDisplay[] = null;
  useMondayAsDay0: boolean = false
  constructor(private guruAppDetailService: GuruApiAppService, private loadingService: LoadingService) { }

  ngOnInit(): void {
    this.loadingService.loaderState
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(state => {
      if (!state.show) {
        this.loadHours()
      }
    });
  }
  loadHours(): void {
    const appDetails = this.guruAppDetailService.appDetails;
    if (appDetails.opening_hours) {
      this.useMondayAsDay0 = appDetails.play_ar_only_on_headphones

      this.hours = appDetails.opening_hours;
      this.formattedHours = this.formatHours();
      this.today = this.getTimeForToday();
      return;
    }
    this.guruAppDetailService.loadAppFullDetails()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(data => {
      this.useMondayAsDay0 = data.play_ar_only_on_headphones

      this.hours = data.opening_hours;
      this.guruAppDetailService.setAppDetails(data);
      this.formattedHours = this.formatHours();
      this.today = this.getTimeForToday();
    })
   
  }
  formatHours(): HourDisplay[] {
    if (this.useMondayAsDay0) {
      moment.updateLocale(moment.locale(), { week: {
        dow: 1, // First day of week is Monday
      }});
    }

    const formatted = this.hours.normal.map(hx => {
      const d = moment().weekday(hx.day);
      const sTx = moment(hx.start_time, ['HH:mm:ss'])
      const eTx = moment(hx.end_time, ['HH:mm:ss'])
      return {
        dayName: d.format('dddd'),
        timeOpen: sTx.format('h:mm A'),
        timeClose: eTx.format('h:mm A'),
        fullDate: d.format('yyyy-MM-DD'),
        prettyDate: d.format('MMM DD, yyyy')
      } as HourDisplay
    });
    console.log(formatted)
    this.hours.special.forEach((val) => {
      console.log(val.date)
      const existingInd = formatted.findIndex((dx) => {
        return dx.fullDate == val.date
      })
      if (existingInd > -1) {
        formatted[existingInd].specialHours = true
        if (val.closed) {
          formatted[existingInd].closed = true
        }
        else {
          const sTx = moment(val.start_time, ['HH:mm:ss'])
          const eTx = moment(val.end_time, ['HH:mm:ss'])
          formatted[existingInd].timeOpen = sTx.format('h:mm A')
          formatted[existingInd].timeClose = eTx.format('h:mm A')

          
        }
      }
    })
    return formatted
  }
  getTimeForToday(): HourDisplay {
    const currentDoW = moment().format('dddd');
    const filt =  this.formattedHours.filter(hd => hd.dayName == currentDoW)
    return filt[0]
  }

  // formatSpec

}
