import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { NgbDateStruct, NgbCalendar, NgbDatepickerModule, NgbDatepickerI18n, NgbDatepicker, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { GuruApiEventService } from '../guru-api/guru-api-events.service';
import { LoadingService } from '../services/loading/loading.service';
import { takeUntil } from 'rxjs/operators';
import { EventItemModel, EventResponseModel } from '../guru-api/models/events.model';
import { Subject, Subscription } from 'rxjs';
import { GuruApiAppService, } from '../guru-api/guru-api-app.service';
import {Router} from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-events-page',
  templateUrl: './events-page.component.html',
  styleUrls: ['./events-page.component.scss'],
  // encapsulation: ViewEncapsulation.None
  // imports: [NgbDatepickerModule]
})
export class EventsPageComponent implements OnInit {
  // currentDate = moment().startOf('M').toDate();
  currentDate = moment().toDate()
  weekDays = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  days: number[] = [];
  events: EventResponseModel[];
  unsubscribe$: Subject<void> = new Subject<void>();
  selectedDay: number = -1;
  viewMode: number = 1; // 0 for month view, 1 for week view
  notCurrentMonth: boolean = false;
  littleEvents: EventResponseModel[]
  constructor(private guruApiEventService: GuruApiEventService,
    private guruApiAppService: GuruApiAppService,
    private router: Router,
    private loadingService: LoadingService) { }
  ngOnInit(): void {
    this.selectedDay = moment().date()
    // this.loadingService.loaderState
    // .pipe(takeUntil(this.unsubscribe$))
    // .subscribe(state => {
      // if (!state.show) {
        this.guruApiEventService.clearData()
        this.loadEvents();
        
      }
    // });
    
  // }
  ngOnDestroy() {
    // this.guruApiEventService.clearData()
  }
  loadEvents(): void {
    const events = this.guruApiEventService.getEvents();
    if (events && events.length) {
      // this.filterTours(tours);
      // this.events = events.slice(0, 10);
      this.filterData(events);
      return;
    }
    this.loadingService.show()
    this.guruApiEventService.loadEvents(this.currentDate.getMonth()+1)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      data => {
        // this.fil
        // if (data.objects.length == 0) {
        //   return
        // }
        this.filterData(data.objects);
        this.loadingService.hide()
      }
    )
  }
  filterData(eventsData: EventResponseModel[]) {
    const currentMonthYear = moment(this.currentDate).format("MM YYYY");
    const eventList = eventsData.filter(e => moment.unix(e.start_time).format("MM YYYY") == currentMonthYear)
    this.events = eventList;
    this.guruApiEventService.setEvents(eventsData);
    this.loadDays(this.currentDate);
  }
  dateHasEvent(dateInp: number): boolean {
    if (this.events == null) {
      return false
    }
    if (this.events.length == 0) {
      return false
    }
    const targetDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), dateInp)
    const eventsForDate = this.events.filter(e => {
      return moment.unix(e.start_time).format("MM DD YYYY") === moment(targetDate).format("MM DD YYYY")
    });
    return eventsForDate.length > 0
  }
  navigateMonth(inc: number) {
    const currentMonth = this.currentDate.getMonth()
    if (this.viewMode == 1) {
      this.toggleViewMode()
    }
    switch(inc) {
      case 1: {
        this.currentDate.setMonth(currentMonth+1);
        break;
      }
      case -1: {
        this.currentDate.setMonth(currentMonth-1);
      }
    }
    if (this.currentDate.getMonth() != new Date().getMonth()) {
      this.notCurrentMonth = true
    }
    // this.loadDays(this.currentDate);
    this.guruApiEventService.clearData()
    this.loadEvents()
  }
  loadDays(date: Date) {
    if (this.viewMode == 0) {
      this.loadMonth(date)
    } else {
      this.loadWeek(date)
    }
  }
  loadMonth(date: Date) {
    this.days = [];
    const year = date.getFullYear();
    const month = date.getMonth();

    // Find how many days this month has
    const daysInMonth = new Date(year, month + 1, 0).getDate();

    const startDay = new Date(year, month, 1).getDay();

    // Add padding for start day
    for (let i = 0; i < startDay; i++) {
      this.days.push(null);
    }
  
    for (let i = 1; i <= daysInMonth; i++) {
      this.days.push(i);
    }
    const endFillerLeft = 7 - (this.days.length % 7);
    for (let i = 0; i < endFillerLeft; i++) {
      this.days.push(null);
    }
    setTimeout(() => {
      this.setDay(this.selectedDay)
    }, 1000)
    // this.setDay(this.se)
  }
  loadWeek(date: Date) {
    this.days = [];
    const year = date.getFullYear();
    const month = date.getMonth();
    const week = moment(date);
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    var weekStart = moment(date).clone().startOf('week');
    var weekEnd = moment(date).clone().endOf('week');
    // for (let i = 0; i < weekStart.day(); i++) {
    //   this.days.push(null);
    // }

    for (var i = 0; i <= 6; i++) {
      const relativeDate = moment(weekStart).add(i, 'days');
      const date = relativeDate.date()
      this.days.push(date);
      if (date == daysInMonth) {
        break
      }
    }
    // Find how many days this month has
    // const daysInMonth = new Date(year, month + 1, 0).getDate();

    // const startDay = new Date(year, month, 1).getDay();

    // // Add padding for start day
    // for (let i = 0; i < startDay; i++) {
    //   this.days.push(null);
    // }
  
    // for (let i = 1; i <= daysInMonth; i++) {
    //   this.days.push(i);
    // }
    // const endFillerLeft = 7 - (this.days.length % 7);
    // for (let i = 0; i < endFillerLeft; i++) {
    //   this.days.push(null);
    // }
    // setTimeout(() => {
    //   this.setDay(this.selectedDay)
    // }, 1000)
  }
  formatDisplayDate(): string {
    return moment(this.currentDate).format("MMM Y")
  }
  formatEventDuration(ev: EventResponseModel): string {
    const startDate = moment.unix(ev.start_time);
    const endDate = moment.unix(ev.end_time)
    return startDate.format("h:mm A") + " - " + endDate.format("h:mm A")
  }
  formatEventDate(ev: EventResponseModel): string {
    const startDate = moment.unix(ev.start_time);
    return startDate.format("MMMM D, YYYY")
  }
  scrollToDay(day: number) {
    console.log(day);
  }
  goToEvent(id: number, start_time: number) {
    this.router.navigate(["events/" + id], {
      queryParams: {
        start_time: start_time
      }
    })
  }
  setDay(day: number) {
    this.selectedDay = day;
    const dateItems = this.events.filter(ev => {
      return moment.unix(ev.start_time).date() == this.selectedDay
    })
    if (dateItems.length > 0) {
      const firstItemInList = dateItems[0];
      const eventElement = document.getElementById('event-'+firstItemInList.event.id + firstItemInList.start_time);
      document.getElementById("event-list").scrollTop = eventElement.offsetTop - 20
    }
    // const targetItem = 
  }
  // @ViewChild(NgbDatepicker, {static: true}) datepicker: NgbDatepicker;

  // model: NgbDateStruct;
	// date: { year: number; month: number };
  // constructor(public i18n: NgbDatepickerI18n) {}


  // ngOnInit(): void {

  // }
  // navigate(number: number) {
  //   const {state, calendar} = this.datepicker;
  //   this.datepicker.navigateTo(calendar.getNext(state.firstDate, 'm', number));
  // }

  // today() {
  //   const {calendar} = this.datepicker;
  //   this.datepicker.navigateTo(calendar.getToday());
  // }
  // currentMonth(): [NgbDate] {
  //   return [this.datepicker.model.months[0].firstDate]
  // }
  toggleViewMode() {
    switch (this.viewMode) {
      case 0:
        this.viewMode = 1;
        this.currentDate = moment().toDate()
        break
      case 1:
        this.viewMode = 0;
        this.currentDate = moment().startOf('M').toDate();
        break;
    }
    this.loadDays(this.currentDate)
  }
  getMinViableDisplayDate(): number {
    let minDate = 0;
    for (var i = 0; i < this.days.length; i++) {
      if (this.days[i] != null) {
        minDate = this.days[i]
        break
      }
    }
    return minDate
  }
  viewableEventList() {
    if (!this.events) {
      return []
    }
    const mindate = this.getMinViableDisplayDate()
    const res = this.events.filter((ev) => {
      return  moment.unix(ev.start_time).date() == this.selectedDay
    })
    this.littleEvents = res;
    return res
  }
} 
